//React & Material UI Imports
import React, { useState, useEffect, useReducer } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Button,
    IconButton,
    Tabs,
    Tab,
    Fab,
    Box,
    Grid,
    Tooltip,
    ClickAwayListener,
} from "@material-ui/core";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListIcon from "@material-ui/icons/List";
import HotelIcon from "@material-ui/icons/Hotel";
import DescriptionIcon from "@material-ui/icons/Description";
import MapIcon from "@material-ui/icons/Map";
import RoomIcon from "@material-ui/icons/Room";
import EventIcon from "@material-ui/icons/Event";
import PrintIcon from "@material-ui/icons/Print";
import EmailIcon from "@material-ui/icons/Email";
import LinkIcon from "@material-ui/icons/Link";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";

import PreTripInformation from "../_sections/PreTripInformation";

//React Helmet
import { Helmet } from "react-helmet";

//React Router Imports
import { useParams, useLocation } from "react-router-dom";

//Import Page Components
import ItineraryIntroduction from "../_sections/ItineraryIntroduction";
import ItineraryContent from "../_sections/ItineraryContent";
import ItineraryAccommadations from "../_sections/ItineraryAccommadations";
import ExperienceDetailsContent from "../_sections/ExperienceDetailsContent";
import TravellerTestimony from "../_sections/TravellerTestimony";
import ChooseDeparture from "../_sections/ChooseDeparture";
import ContactPersonalTravelAdvisor from "../_sections/ContactPersonalTravelAdvisor";
import LocalInformationContent from "../_sections/LocalInformationContent";
import MoreFromBAndRContent from "../_sections/MoreFromBAndRContent";
import BrIframeModal from "../elements/BrIframeModal";
import MarketoForm from "../../utilities/MarketoForms/MarketoForm";
import BrPhotoCredit from "../elements/BrPhotoCredit";

//Global Components
import Loading from "../elements/Loading";
import FourZeroFour from "../elements/FourZeroFour";

//API Imports
import { api } from "../../api/api";

//Wetu User Lookup
import userLookup from "../../utilities/Wetu/userLookup";
import {
    copyUrl,
    isValidDate,
    scrollToTargetAdjusted,
} from "../../utilities/HelperFunctions/helperFunctions";

//Import CSS Styles
import "../../theme/css/itinerary.css";
import ScrollToTopButton from "../elements/ScrollToTopButton";

//Build Component Styles
const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        background: "#fff",
    },
    backToTop: {
        color: "#fff",
        backgroundColor: "#194666",
        "&:hover": {
            backgroundColor: "#194666",
        },
    },
}));

//Convert URLs to Hyperlinks
const urlToHyperlink = (html) => {
    if (html) {
        return html.replace(/(https?:\/\/)([^< ]+)/g, '<a target="_blank" href="$&">$2</a>');
    }
};

//Define Data Fetch Helper Functions
const buildPinArray = (itineraryObject, fetchProperty) => {
    if (fetchProperty === "hotel") {
        let itineraryHotelsArray = [];

        itineraryObject.legs.map((leg) => {
            itineraryHotelsArray.push(leg.content_entity_id);
        });

        return itineraryHotelsArray;
    } else if (fetchProperty === "destination") {
        // let itineraryDestinationsArray = [];

        // itineraryObject.legs.map((leg) => {
        //     itineraryDestinationsArray.push(leg.destination_content_entity_id);
        // });
        // return itineraryDestinationsArray;

        //New Structure//////////////////////////////////////////////////////////////
        let itineraryDestinationsArray_NEW = [];

        itineraryObject.legs.map((leg, i) => {
            let destinationContentIds = [];

            if (leg.periods) {
                leg.periods.map((period) => {
                    if (period.activities) {
                        period.activities.map((activity) => {
                            if (activity.type == "Destination") {
                                destinationContentIds.push(activity.content_entity_id);
                            }
                        });
                    }
                });
            }
            destinationContentIds.push(leg.destination_content_entity_id);
            itineraryDestinationsArray_NEW.push(...destinationContentIds);
        });

        return itineraryDestinationsArray_NEW;
        ///////////////////////////////////////////////////////////////////////////////
    } else if (fetchProperty === "activity") {
        let itineraryActivitiesArray = [];

        itineraryObject.legs.map((leg, i) => {
            leg.periods.map((period, j) => {
                if (period.activities) {
                    period.activities.map((activity) => {
                        itineraryActivitiesArray.push(activity.content_entity_id);
                    });
                }
            });
        });

        return itineraryActivitiesArray;
    } else if (fetchProperty === "altHotels") {
        let altItineraryHotelsArray = [];

        itineraryObject.legs.map((leg) => {
            if (leg.alternate_accommodations) {
                leg.alternate_accommodations.map((altHotel) => {
                    altItineraryHotelsArray.push(altHotel.content_entity_id);
                });
            }
        });

        return altItineraryHotelsArray;
    } else if (fetchProperty === "stops") {
        let itineraryStopsArray = [];

        itineraryObject.legs.map((leg, i) => {
            if (leg.stops) {
                leg.stops.map((stop, j) => {
                    itineraryStopsArray.push(stop.content_entity_id);
                });
            }
        });

        return itineraryStopsArray;
    }
};

const buildCountryArray = (itineraryDestinationsArray) => {
    let itineraryCountriesArray = [];

    itineraryDestinationsArray.map((pin) => {
        itineraryCountriesArray.push(pin.position.country_content_entity_id);
    });

    let unique = itineraryCountriesArray.filter((v, i, a) => a.indexOf(v) == i);

    return unique;
};

//Define Pick Up/ Dropoff Data Extraction Helper Functions
const getPickUpDropOffActivityData = (activityArray, returnProperty) => {
    if (activityArray) {
        for (var i = 0; i < activityArray.length; i++) {
            if (
                activityArray[i].name.toLowerCase().trim() == "trip start" ||
                activityArray[i].name.toLowerCase().trim() == "trip finish"
            ) {
                return activityArray[i][returnProperty];
            } else {
            }
        }
    }
};

const getPickUpDropOff = (
    itinerary,
    finalLeg,
    itineraryRelatedContent,
    pickUpDropOff,
    property,
    subProperty
) => {
    let entityId;
    if (pickUpDropOff == "pick up") {
        entityId = getPickUpDropOffActivityData(
            itinerary.legs[0].periods[0].activities,
            "content_entity_id"
        );
    } else {
        entityId = getPickUpDropOffActivityData(
            itinerary.legs[finalLeg].periods[0].activities,
            "content_entity_id"
        );
    }
    for (var i = 0; i < itineraryRelatedContent.length; i++) {
        if (itineraryRelatedContent[i].map_object_id === entityId) {
            if (subProperty) {
                return itineraryRelatedContent[i][property][subProperty];
            } else {
                return itineraryRelatedContent[i][property];
            }
        }
    }
};

function search(keyValue, key, array) {
    if (array) {
        for (var i = 0; i < array.length; i++) {
            if (array[i][key] === keyValue) {
                return array[i];
            }
        }
    }
}

export function addDays(date, days) {
    //Function to shift date ahead by a certain amount
    //of minutes. This is required to account for dates
    //that span DST changes.
    function addMinutes(date, minutes) {
        const copy = new Date(date.getTime() + minutes * 60000);
        return copy;
    }

    //Shift date ahead 60 minutes (see above)
    let shiftedDate = addMinutes(date, 60);

    //Shift date ahead by correct number of days
    const copy = new Date(Number(shiftedDate));
    copy.setDate(date.getDate() + days);
    return copy;
}

const Itinerary = (props) => {
    const { wetuid } = useParams();
    const { loginState } = props;
    const classes = useStyles();
    const mobileWidth = useMediaQuery("(max-width:959px)");
    //const isPrint = useMediaQuery("print");
    // const toTopScrollTrigger = useScrollTrigger({
    //     threshold: 500,
    // });

    const areNotificationsDisabled = () => {
        if (searchParams.get("disableNotifications") === "true") {
            return true;
        }
        if (searchParams.get("m") === "d") {
            return true;
        }
        return false;
    };

    const wetuId = wetuid;
    const [isError, setIsError] = useState(false);
    const [isShareTripModalOpen, setIsShareTripModalOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [itinerary, setItinerary] = useState(null);
    const [tripContent, setTripContent] = useState(null);
    const [departureContent, setDepartureContent] = useState({});
    const [otpContent, setOtpContent] = useState(null);
    const [isOtpComplete, setIsOtpComplete] = useState(false);
    const [itineraryHotelContent, setItineraryHotelContent] = useState(null);
    const [itineraryAltHotelContent, setItineraryAltHotelContent] = useState(null);
    const [itineraryRelatedContent, setItineraryRelatedContent] = useState(null);
    const [itineraryCountryContent, setItineraryCountryContent] = useState(null);
    const [itineraryDestinationContent, setItineraryDestinationContent] = useState(null);

    //Button Box State
    const [copyTooltipOpen, setCopyTooltipOpen] = useState(false);
    const [isLightButtons, setisLightButtons] = useState(false);

    //Print Generation State
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [isPrintError, setIsPrintError] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    let location = useLocation();
    let searchParams = new URLSearchParams(useLocation().search);
    let disableNotifications = areNotificationsDisabled();
    let isPrint = props.isPrint;

    //Intersection Observer
    if (document.querySelector(".button-box")) {
        window.onscroll = () => {
            let buttonBox = document.querySelector(".button-box");
            if (buttonBox) {
                let buttonBoxTopPos = buttonBox.getBoundingClientRect().top;
                if (buttonBoxTopPos < 155) {
                    setisLightButtons(true);
                } else {
                    setisLightButtons(false);
                }
            }
        };
    }
    ////////////////////////////////////////////////////////////////

    let testImages = () => {
        Promise.all(
            Array.from(document.images)
                .filter((img) => !img.complete)
                .map(
                    (img) =>
                        new Promise((resolve) => {
                            img.onload = img.onerror = resolve;
                        })
                )
        ).then(() => {
            setImagesLoaded(true);
        });
    };

    const printToPdf = () => {
        //IF ENVIRONMENT IS STAGING OR DEVELOPMENT (e.g. staging.media)
        setIsGeneratingPdf(true);
        api(`${process.env.REACT_APP_API_ENDPOINT}/v1/generatePdf`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: {
                urlToPdf: `${process.env.REACT_APP_RETURN_URL}${location.pathname}?disableNotifications=true&isPrint=true`,
                itineraryName: itinerary.name,
            },
        })
            .then((result) => {
                if (result.success) {
                    // window.open(
                    //     `https://staging.media.butterfield.com${result.mediaServerPath}`,
                    //     "_blank"
                    // );
                    let a = document.createElement("a");
                    a.href = `${process.env.REACT_APP_MEDIA_ENDPOINT}${result.mediaServerPath}`;
                    a.setAttribute("target", "_blank");
                    a.click();
                    setIsGeneratingPdf(false);
                    setIsPrintError(false);
                } else {
                    setIsGeneratingPdf(false);
                    setIsPrintError(true);
                    console.log("Something went wrong :/");
                }
            })
            .catch((err) => {
                console.log(err);
            });

        //IF PRODUCTION (e.g. media)
        //...
    };

    const refreshPageData = () => {
        //Request WETU Itinerary + Additional Information
        api(process.env.REACT_APP_API_ENDPOINT + "/v1/itineraries/" + wetuId)
            .then((itineraryObject) => {
                if (!itineraryObject.error) {
                    setItinerary(itineraryObject);
                    setIsDisabled(itineraryObject.is_disabled);
                    if (itineraryObject.notification_frequency === "None") {
                        disableNotifications = true;
                    }
                    api(
                        process.env.REACT_APP_API_ENDPOINT +
                            "/v1/itineraries/" +
                            wetuId +
                            "/relatedContent/"
                    ).then((itineraryRelatedContentObject) => {
                        setItineraryRelatedContent(itineraryRelatedContentObject.pins);
                    });

                    let hotelsFetchArray = buildPinArray(itineraryObject, "hotel");
                    let altHotelsFetchArray = buildPinArray(itineraryObject, "altHotels");

                    let destinationsFetchArray = itineraryObject.tags
                        ? itineraryObject.tags.indexOf("By Sea") > -1
                            ? buildPinArray(itineraryObject, "stops")
                            : buildPinArray(itineraryObject, "destination")
                        : buildPinArray(itineraryObject, "destination");

                    api(process.env.REACT_APP_API_ENDPOINT + "/v1/itineraries/pins/", {
                        method: "POST",
                        body: hotelsFetchArray,
                    }).then((itineraryHotelContentObject) => {
                        setItineraryHotelContent(itineraryHotelContentObject);
                    });

                    api(process.env.REACT_APP_API_ENDPOINT + "/v1/itineraries/pins/", {
                        method: "POST",
                        body: altHotelsFetchArray,
                    }).then((itineraryHotelContentObject) => {
                        setItineraryAltHotelContent(itineraryHotelContentObject);
                    });

                    api(process.env.REACT_APP_API_ENDPOINT + "/v1/itineraries/pins/", {
                        method: "POST",
                        body: destinationsFetchArray,
                    })
                        .then((itineraryDestinationContentObject) => {
                            if (!itineraryDestinationContentObject.error) {
                                let countryFetchArray = buildCountryArray(
                                    itineraryDestinationContentObject
                                );
                                setItineraryDestinationContent(itineraryDestinationContentObject);
                                api(process.env.REACT_APP_API_ENDPOINT + "/v1/itineraries/pins/", {
                                    method: "POST",
                                    body: countryFetchArray,
                                })
                                    .then((itineraryCountryContentObject) => {
                                        setItineraryCountryContent(itineraryCountryContentObject);
                                    })
                                    .then(() => {
                                        if (isPrint) {
                                            testImages();
                                        }
                                    });
                            } else {
                                setIsError(true);
                                throw new Error("Error with Destination Fetch");
                            }
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    if (!disableNotifications) {
                        api(`https://ipapi.co/json/`).then((locationObject) => {
                            api(
                                process.env.REACT_APP_API_ENDPOINT +
                                    "/v1/emails/itineraryViewNotification",
                                {
                                    method: "POST",
                                    body: {
                                        tripDesignerEmail: search(
                                            itineraryObject.operator_user_id,
                                            "id",
                                            userLookup
                                        )?.email,
                                        data: {
                                            itineraryName: itineraryObject.name,
                                            itineraryId: itineraryObject.identifier,
                                            itineraryReferenceNumber:
                                                itineraryObject.reference_number,
                                            itineraryClientName: itineraryObject.client_name,
                                            viewedInCountry: locationObject.country_name,
                                            viewedInState: locationObject.region,
                                            viewedInCity: locationObject.city,
                                            viewedOn:
                                                window.innerWidth < 960 ? "Mobile" : "Desktop",
                                        },
                                    },
                                }
                            );
                        });
                    }
                } else {
                    setIsError(true);
                    throw new Error("Error with Itinerary Fetch");
                }
            })
            .catch((err) => {
                console.log(err);
            });

        //Request Guest Record by Itinerary ID
        api(
            `${process.env.REACT_APP_API_ENDPOINT}/v1/guests/itineraries/${wetuId}`
            // "https://endpoint.scribesoft.com/v1/orgs/3487/requests/19503?accesstoken=0bdb0da4-816c-436f-adfd-582a3947c135",
            // { method: "POST", body: { br_guestitineraryid: wetuId } }
        ).then((guest) => {
            if ("guests" in guest) {
                api(
                    process.env.REACT_APP_API_ENDPOINT +
                        "/v1/departures/" +
                        guest.guests[0].p15_tripdeparturesid
                ).then((guestsDeparture) => {
                    setDepartureContent({
                        departures: [{ ...guestsDeparture.departure }],
                    });
                    api(
                        process.env.REACT_APP_API_ENDPOINT +
                            "/v1/trips/" +
                            guestsDeparture.departure.p15_tripid
                    ).then((tripObject) => {
                        setTripContent(tripObject);
                    });
                    //OTP Stuff
                    api(
                        `${process.env.REACT_APP_API_ENDPOINT}/v1/otp/trips/${guestsDeparture.departure.p15_tripid}/departures/${guest.guests[0].p15_tripdeparturesid}/guests/${guest.guests[0].p15_guestid}`
                    ).then((otpData) => {
                        let guideFetchObject = {
                            departure_id: guest.guests[0].p15_tripdeparturesid,
                        };
                        if (
                            otpData.otp.blog &&
                            otpData.otp.gettingInShape &&
                            otpData.otp.gettingThere &&
                            otpData.otp.packingList
                        ) {
                            setOtpContent(otpData);
                            setIsOtpComplete(true);
                        }
                        api("https://api.butterfield.com/v1/my/guides", {
                            method: "POST",
                            body: guideFetchObject,
                        }).then((guidesObject) => {
                            setOtpContent({
                                ...otpData,
                                guides: guidesObject.guides ? guidesObject.guides : null,
                            });
                        });
                    });
                });
            }
        });
        //Request Trip and Departure by Itinerary ID
        // api(
        //     process.env.REACT_APP_API_ENDPOINT +
        //         "/v1/trips/itineraries/" +
        //         wetuId
        // ).then((tripObject) => {
        //     setTripContent(tripObject);
        // });
        api(process.env.REACT_APP_API_ENDPOINT + "/v1/departures/itineraries/" + wetuId).then(
            (departureObject) => {
                if ("error" in departureObject) {
                } else {
                    setDepartureContent(departureObject);
                    if (departureObject.departures.length > 0) {
                        api(
                            process.env.REACT_APP_API_ENDPOINT +
                                "/v1/trips/" +
                                departureObject.departures[0].p15_tripid
                        ).then((tripObject) => {
                            setTripContent(tripObject);
                        });
                    } else {
                        setTripContent({});
                    }
                }
            }
        );
    };

    let finalLeg;
    let tripType = "Bespoke";
    let isBooked = null;
    let bySea = false;
    let selfGuided = false;

    if (itinerary) {
        finalLeg = itinerary.legs.length - 1;
        if (itinerary.tags) {
            if (itinerary.tags.indexOf("**Small Group") > -1) {
                tripType = "Small Group";
            }
            if (itinerary.tags.indexOf("Self-guided") > -1) {
                tripType = "Self-guided";
                selfGuided = true;
            }
            bySea = itinerary.tags.indexOf("By Sea") > -1 ? true : false;
        }
        isBooked = itinerary.type === "Sample" ? false : true;
    }

    let tripStart;
    let tripEnd;
    const dateDisplayOptions = {
        //  weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone: "UTC",
    };
    if (isBooked) {
        tripStart = new Date(itinerary.start_date);
        tripEnd = addDays(tripStart, itinerary.days - 1);
    }

    //TAB COMPONENT LABELS
    let tabLabels;
    if (isBooked != null) {
        isBooked && isOtpComplete
            ? (tabLabels = [
                  {
                      name: "Itinerary",
                      anchor: "itinerary",
                      icon: <ListIcon />,
                  },
                  {
                      name: "Accommodations",
                      anchor: "accommodations",
                      icon: <HotelIcon />,
                  },
                  {
                      name: "Details",
                      anchor: "trip_details",
                      icon: <DescriptionIcon />,
                  },
                  {
                      name: "Prepare",
                      anchor: "prepare",
                      icon: <AssignmentTurnedInIcon />,
                  },
                  {
                      name: "Destinations",
                      anchor: "country_information",
                      icon: <RoomIcon />,
                  },
              ])
            : isBooked || tripType == "Bespoke" || tripType == "Self-guided"
            ? (tabLabels = [
                  {
                      name: "Itinerary",
                      anchor: "itinerary",
                      icon: <ListIcon />,
                  },
                  {
                      name: "Accommodations",
                      anchor: "accommodations",
                      icon: <HotelIcon />,
                  },
                  {
                      name: "Details",
                      anchor: "trip_details",
                      icon: <DescriptionIcon />,
                  },
                  {
                      name: "Destinations",
                      anchor: "country_information",
                      icon: <RoomIcon />,
                  },
              ])
            : (tabLabels = [
                  {
                      name: "Itinerary",
                      anchor: "itinerary",
                      icon: <ListIcon />,
                  },
                  {
                      name: "Accommodations",
                      anchor: "accommodations",
                      icon: <HotelIcon />,
                  },
                  {
                      name: "Details",
                      anchor: "trip_details",
                      icon: <DescriptionIcon />,
                  },
                  {
                      name: "Destinations",
                      anchor: "country_information",
                      icon: <RoomIcon />,
                  },
                  {
                      name: "Book Now",
                      anchor: "choose_departure",
                      icon: <EventIcon />,
                  },
              ]);
    }

    let pickUpDropOff = {};

    if (itineraryRelatedContent) {
        pickUpDropOff = {
            pickUpCity: getPickUpDropOff(
                itinerary,
                finalLeg,
                itineraryRelatedContent,
                "pick up",
                "position",
                "destination"
            ),

            pickUpLocation: getPickUpDropOff(
                itinerary,
                finalLeg,
                itineraryRelatedContent,
                "pick up",
                "name"
            ),

            pickUpTimeSlot: getPickUpDropOffActivityData(
                itinerary.legs[0].periods[0].activities,
                "time_slot"
            ),

            pickUpTime: getPickUpDropOffActivityData(
                itinerary.legs[0].periods[0].activities,
                "start_time"
            ),
            pickUpDescription: getPickUpDropOffActivityData(
                itinerary.legs[0].periods[0].activities,
                "reference"
            ),

            dropOffCity: getPickUpDropOff(
                itinerary,
                finalLeg,
                itineraryRelatedContent,
                "drop off",
                "position",
                "destination"
            ),

            dropOffLocation: getPickUpDropOff(
                itinerary,
                finalLeg,
                itineraryRelatedContent,
                "drop off",
                "name"
            ),

            dropOffTimeSlot: getPickUpDropOffActivityData(
                itinerary.legs[finalLeg].periods[0].activities,
                "time_slot"
            ),

            dropOffTime: getPickUpDropOffActivityData(
                itinerary.legs[finalLeg].periods[0].activities,
                "start_time"
            ),

            dropOffDescription: getPickUpDropOffActivityData(
                itinerary.legs[finalLeg].periods[0].activities,
                "reference"
            ),
        };
        if (!pickUpDropOff.dropOffCity && !pickUpDropOff.pickUpCity) {
            pickUpDropOff = null;
        }
    }

    let experienceDetailsData;

    if (itineraryRelatedContent && tripContent) {
        experienceDetailsData = {
            price: itinerary.price,
            inclusions: itinerary.price_includes,
            exclusions: itinerary.price_excludes,
            suggestedLength: tripContent.trip ? tripContent.trip.p15_tripduration : "",
            bestTimeToGo: "April - September",
            activityLevel: tripContent.trip ? tripContent.trip.br_activitylevel : "",
            activityLevelLabel: "(Recreational)",
            activityLevelDescription: tripContent.trip?.cms_activity_level_description
                ? tripContent.trip.cms_activity_level_description
                : null,
            supportLevel: tripContent.trip ? tripContent.trip.br_supportlevel : "",
            equipmentList: [
                // {
                //     name: "B&R Custom Road Bike",
                //     subtitle: "high performance | drop bar",
                //     description:
                //         "Custom-designed in partnership with former B&R guide, Tim McDermott, this bike features sporty geometry with an aluminum frame and carbon fork, as well as powerful disc brakes, making it perfect for flats and hills alike. With the B&R traveller top of mind, the bike is comfortable, lightweight and equipped with top-end Shimano Ultegra components. Material: Aluminum with carbon fork | Weight: Approx. 20 lbs | Gears: 2 x 11 speed | Components: Shimano Ultegra R8000 | Brakes: Mechanical disc | Handlebars: Drop bars | Tires: 700 x 30mm",
                //     image:
                //         "https://images.unsplash.com/photo-1485965120184-e220f721d03e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
                // },
                // {
                //     name: "B&R Custom Road Bike 2",
                //     subtitle: "high performance | drop bar",
                //     description:
                //         "Custom-designed in partnership with former B&R guide, Tim McDermott, this bike features sporty geometry with an aluminum frame and carbon fork, as well as powerful disc brakes, making it perfect for flats and hills alike. With the B&R traveller top of mind, the bike is comfortable, lightweight and equipped with top-end Shimano Ultegra components. Material: Aluminum with carbon fork | Weight: Approx. 20 lbs | Gears: 2 x 11 speed | Components: Shimano Ultegra R8000 | Brakes: Mechanical disc | Handlebars: Drop bars | Tires: 700 x 30mm",
                //     image:
                //         "https://images.unsplash.com/photo-1571333250630-f0230c320b6d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
                // },
            ],
        };

        if (departureContent.departures.length > 0) {
            departureContent.departures[0].gears.map((gear) => {
                experienceDetailsData.equipmentList.push({
                    name: gear.equipment_name,
                    subtitle: gear.equipment_type,
                    description: gear.equipment_description
                        ? gear.equipment_description
                        : "Custom-designed in partnership with former B&R guide, Tim McDermott, this bike features sporty geometry with an aluminum frame and carbon fork, as well as powerful disc brakes, making it perfect for flats and hills alike. With the B&R traveller top of mind, the bike is comfortable, lightweight and equipped with top-end Shimano Ultegra components. Material: Aluminum with carbon fork | Weight: Approx. 20 lbs | Gears: 2 x 11 speed | Components: Shimano Ultegra R8000 | Brakes: Mechanical disc | Handlebars: Drop bars | Tires: 700 x 30mm",
                    image: gear.equipment_url
                        ? gear.equipment_url
                        : "https://dummyimage.com/1200x800/000/fff.png&text=No+Equipment+Image",
                });
            });
        } else if (tripContent.trip) {
            tripContent.trip.departures[0].gears.map((gear) => {
                experienceDetailsData.equipmentList.push({
                    name: gear.equipment_name,
                    subtitle: gear.equipment_type,
                    description: gear.equipment_description
                        ? gear.equipment_description
                        : "Custom-designed in partnership with former B&R guide, Tim McDermott, this bike features sporty geometry with an aluminum frame and carbon fork, as well as powerful disc brakes, making it perfect for flats and hills alike. With the B&R traveller top of mind, the bike is comfortable, lightweight and equipped with top-end Shimano Ultegra components. Material: Aluminum with carbon fork | Weight: Approx. 20 lbs | Gears: 2 x 11 speed | Components: Shimano Ultegra R8000 | Brakes: Mechanical disc | Handlebars: Drop bars | Tires: 700 x 30mm",
                    image: gear.equipment_url
                        ? gear.equipment_url
                        : "https://dummyimage.com/1200x800/000/fff.png&text=No+Equipment+Image",
                });
            });
        } else {
            experienceDetailsData.equipmentList = [];
        }
    }

    // START ITINERARY DUMMY DATA //
    let extendYourTripData = [
        {
            title: "Your Own Villa in Tuscany",
            image: "https://images.unsplash.com/photo-1584605043414-e32fc1dc0566?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
        },
        {
            title: "Your Own Villa in Tuscany",
            image: "https://images.unsplash.com/photo-1583743089745-1b4da736bd6a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1070&q=80",
        },
        {
            title: "Your Own Villa in Tuscany",
            image: "https://images.unsplash.com/photo-1570544820611-2dab6c0871e4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1071&q=80",
        },
    ];
    // END ITINERARY DUMMY DATA //
    const buildHeroImageArray = () => {
        let imagesArray = [];
        if (itinerary) {
            if (itinerary.cover_images) {
                itinerary.cover_images.map((image) => {
                    if (!image.archived) {
                        imagesArray.push(
                            mobileWidth
                                ? `https://wetu.com/ImageHandler/c600x600/${image.url_fragment}`
                                : `https://wetu.com/ImageHandler/c1344x595/${image.url_fragment}`
                        );
                    }
                });

                if (imagesArray.length > 0) {
                    return imagesArray;
                } else if (itineraryDestinationContent) {
                    itineraryDestinationContent.map((destination) => {
                        if (destination.destination_image) {
                            imagesArray.push(
                                mobileWidth
                                    ? `https://wetu.com/ImageHandler/c600x600/${destination.destination_image.url_fragment}`
                                    : `https://wetu.com/ImageHandler/c1344x595/${destination.destination_image.url_fragment}`
                            );
                        }
                    });
                }
            } else if (itineraryDestinationContent) {
                itineraryDestinationContent.map((destination) => {
                    if (destination.destination_image) {
                        imagesArray.push(
                            mobileWidth
                                ? `https://wetu.com/ImageHandler/c600x600/${destination.destination_image.url_fragment}`
                                : `https://wetu.com/ImageHandler/c1344x595/${destination.destination_image.url_fragment}`
                        );
                    }
                });
            }
        }
        return imagesArray;
    };

    let heroImages = buildHeroImageArray();

    useEffect(() => {
        refreshPageData();
    }, []);

    const buildItineraryTab = () => {
        return (
            <ItineraryContent
                urlToHyperlink={urlToHyperlink}
                tripType={tripType}
                isBooked={isBooked}
                itinerary={itinerary}
                itineraryRelatedContent={itineraryRelatedContent}
                finalLeg={finalLeg}
                pickUpDropOff={pickUpDropOff}
                itineraryHotelContent={itineraryHotelContent}
                itineraryAltHotelContent={itineraryAltHotelContent}
                itineraryDestinationContent={itineraryDestinationContent}
                tripStart={tripStart}
                dateDisplayOptions={dateDisplayOptions}
                search={search}
                addDays={addDays}
                isValidDate={isValidDate}
                mobileWidth={mobileWidth}
                isPrint={isPrint}
                selfGuided={selfGuided}
            />
        );
    };
    const buildAccommadationTab = () => {
        return (
            <ItineraryAccommadations
                // tripType={tripType}
                itinerary={itinerary}
                isBooked={isBooked}
                // itineraryRelatedContent={itineraryRelatedContent}
                // pickUpDropOff={pickUpDropOff}
                itineraryHotelContent={itineraryHotelContent}
                mobileWidth={mobileWidth}
                isPrint={isPrint}
                tripStart={tripStart}
                addDays={addDays}
                // itineraryDestinationContent={itineraryDestinationContent}
            />
        );
    };

    const buildExperienceDetailsTab = () => {
        return (
            <div>
                <ExperienceDetailsContent
                    urlToHyperlink={urlToHyperlink}
                    tripType={tripType}
                    isBooked={isBooked}
                    pickUpDropOff={pickUpDropOff}
                    tripContent={tripContent}
                    departureContent={departureContent}
                    experienceDetailsData={experienceDetailsData}
                    itinerary={itinerary}
                    itineraryRelatedContent={itineraryRelatedContent}
                    itineraryHotelContent={itineraryHotelContent}
                    itineraryCountryContent={itineraryCountryContent}
                    isPrint={isPrint}
                    otpContent={otpContent}
                    isOtpComplete={isOtpComplete}
                    dateDisplayOptions={dateDisplayOptions}
                    tripStart={tripStart}
                    tripEnd={tripEnd}
                />
            </div>
        );
    };

    const buildChooseDepartureTab = () => {
        return (
            <div>
                <ChooseDeparture
                    itinerary={itinerary}
                    tripContent={tripContent}
                    departureContent={departureContent}
                />
            </div>
        );
    };

    const buildCountryInformationTab = () => {
        return (
            <div>
                <LocalInformationContent
                    bySea={bySea}
                    itineraryDestinationContent={itineraryDestinationContent}
                    itineraryCountryContent={itineraryCountryContent}
                    itinerary={itinerary}
                    itineraryRelatedContent={itineraryRelatedContent}
                    itineraryHotelContent={itineraryHotelContent}
                    mobileWidth={mobileWidth}
                    isPrint={isPrint}
                    isBooked={isBooked}
                />
            </div>
        );
    };

    const buildMoreFromBAndRTab = () => {
        return (
            <div>
                <MoreFromBAndRContent
                    itinerary={itinerary}
                    extendYourTripData={extendYourTripData}
                    tripContent={tripContent}
                />
            </div>
        );
    };

    const buildDocumentsTab = () => {
        return (
            <div>
                <h1>Documents go here :)</h1>
            </div>
        );
    };

    //Build tab labels from tabLabels array
    function buildTabLabels(tabs) {
        return tabs.map((tab) => {
            return {
                label: tab.name,
            };
        });
    }

    const [value, setValue] = React.useState("overview");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const buildItinNavbar = (tabs) => {
        return (
            <>
                {mobileWidth ? (
                    <BottomNavigation
                        value={value}
                        onChange={handleChange}
                        className={classes.root}
                        style={{
                            position: "fixed",
                            bottom: "0",
                            zIndex: 99,
                            width: "100%",
                        }}
                    >
                        {tabs.map((tab) => (
                            <BottomNavigationAction
                                value={tab.anchor}
                                label={tab.name}
                                onClick={() => {
                                    scrollToTargetAdjusted(tab.anchor, mobileWidth);
                                }}
                                icon={tab.icon}
                            />
                        ))}
                    </BottomNavigation>
                ) : (
                    <Box
                        style={{
                            position: "sticky",
                            top: "60px",
                            zIndex: 99,
                            background: "rgba(73, 73, 73, 1)",
                            padding: "0.5rem 0",
                        }}
                    >
                        <Grid
                            container
                            style={{
                                width: "100%",
                                maxWidth: "1200px",
                                margin: "auto",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            {tabs.map((tab) => (
                                <Grid item xs={2}>
                                    <Tooltip title={tab.name} aria-label={tab.name}>
                                        <Button
                                            onClick={() => {
                                                scrollToTargetAdjusted(tab.anchor, mobileWidth);
                                            }}
                                            style={{
                                                color: "#fff",
                                                width: "100%",
                                            }}
                                        >
                                            {tab.icon}
                                            {/* <ListIcon style={{ color: "#fff" }} /> */}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>
                        {/* <Tabs
                            variant={"fullWidth"}
                            scrollButtons="on"
                            indicatorColor="primary"
                            textColor="primary"
                            style={{
                                background: "rgb(73, 73, 73)",
                            }}
                        >
                            {tabs.map((tab) => (
                                <Tab
                                    icon={tab.icon}
                                    label={tab.name}
                                    onClick={() => {
                                        scrollToTargetAdjusted(
                                            tab.anchor,
                                            mobileWidth
                                        );
                                    }}
                                />
                            ))}
                            <Tab
                                icon={<PrintIcon />}
                                label={"Print"}
                                href="?disableNotifications=true&isPrint=true"
                                target="_blank"
                            />
                        </Tabs> */}
                        <Box
                            className={"button-box"}
                            style={{
                                maxWidth: "1320px",
                                margin: "auto",
                                zIndex: 999,
                            }}
                        >
                            <Grid
                                container
                                style={{
                                    // width: "20%",
                                    // float: "right",
                                    maxWidth: "150px",
                                    position: "absolute",
                                    right: "30px",
                                    top: "-50px",
                                }}
                            >
                                <Grid item xs={4}>
                                    {isGeneratingPdf ? (
                                        <IconButton aria-label="delete">
                                            <AutorenewIcon
                                                style={{
                                                    transition: "all 1.25s ease 0s",
                                                    color: isLightButtons ? "#fff" : "#494949",
                                                }}
                                            />
                                        </IconButton>
                                    ) : isPrintError ? (
                                        <Tooltip
                                            PopperProps={{
                                                disablePortal: true,
                                            }}
                                            open={true}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            // placement="top"
                                            arrow={true}
                                            title="Oops, something went wrong... Please try again."
                                        >
                                            <IconButton
                                                aria-label="delete"
                                                onClick={() => {
                                                    printToPdf();
                                                }}
                                                // href="?disableNotifications=true&isPrint=true"
                                            >
                                                <ErrorOutlineIcon
                                                    style={{
                                                        transition: "all 1.25s ease 0s",
                                                        color: isLightButtons ? "#fff" : "#494949",
                                                    }}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    ) : (
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                printToPdf();
                                            }}
                                            // href="?disableNotifications=true&isPrint=true"
                                        >
                                            <PrintIcon
                                                style={{
                                                    transition: "all 1.25s ease 0s",
                                                    color: isLightButtons ? "#fff" : "#494949",
                                                }}
                                            />
                                        </IconButton>
                                    )}
                                </Grid>
                                <Grid item xs={4}>
                                    <IconButton
                                        aria-label="delete"
                                        href={`mailto:?subject=Check%20out%20this%20itinerary%20&body=Check%20out%20this%20itinerary%20from%20Butterfield%20%26%20Robinson:%20${process.env.REACT_APP_RETURN_URL}${location.pathname}`}
                                        // onClick={() =>
                                        //     setIsShareTripModalOpen(
                                        //         !isShareTripModalOpen
                                        //     )
                                        // }
                                    >
                                        <EmailIcon
                                            style={{
                                                transition: "all 1.25s ease 0s",
                                                color: isLightButtons ? "#fff" : "#494949",
                                            }}
                                        />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={4}>
                                    <ClickAwayListener
                                        onClickAway={() => {
                                            setCopyTooltipOpen(false);
                                        }}
                                    >
                                        <div>
                                            <Tooltip
                                                PopperProps={{
                                                    disablePortal: true,
                                                }}
                                                onClose={() => {
                                                    setCopyTooltipOpen(false);
                                                }}
                                                open={copyTooltipOpen}
                                                disableFocusListener
                                                disableHoverListener
                                                disableTouchListener
                                                title="Link copied"
                                            >
                                                <IconButton
                                                    style={{
                                                        transition: "all 1.25s ease 0s",
                                                        color: isLightButtons ? "#fff" : "#494949",
                                                    }}
                                                    aria-label="copy-url"
                                                    onClick={() => {
                                                        setCopyTooltipOpen(true);
                                                        copyUrl();
                                                    }}
                                                >
                                                    <LinkIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </ClickAwayListener>
                                    {/* <Tooltip title={"Copied"}>
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => {
                                                copyUrl();
                                            }}
                                        >
                                            <LinkIcon />
                                        </IconButton>
                                    </Tooltip> */}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                )}
            </>
        );
    };

    //Push itinerary tab content into array
    const buildTabArray = () => {
        let arr = [];

        if (itinerary)
            arr.push(
                buildItineraryTab(),
                buildAccommadationTab(),
                buildExperienceDetailsTab(),
                isBooked ? buildCountryInformationTab() : buildChooseDepartureTab()

                //buildMoreFromBAndRTab()
                // buildDocumentsTab()
            );

        return arr;
    };

    return (
        <>
            {isError || isDisabled ? (
                <FourZeroFour />
            ) : (
                <>
                    <div className={classes.root + " detailed-itinerary"}>
                        {itinerary && itineraryDestinationContent ? (
                            <>
                                <Helmet>
                                    <meta charSet="utf-8" />
                                    <title>{itinerary.name} | Butterfield & Robinson</title>
                                </Helmet>
                                <ItineraryIntroduction
                                    urlToHyperlink={urlToHyperlink}
                                    isBooked={isBooked}
                                    tripType={tripType}
                                    itinerary={itinerary}
                                    heroImages={heroImages}
                                    tripStart={tripStart}
                                    tripEnd={tripEnd}
                                    dateDisplayOptions={dateDisplayOptions}
                                    isValidDate={isValidDate}
                                    mobileWidth={mobileWidth}
                                    isPrint={isPrint}
                                    selfGuided={selfGuided}
                                />
                                {isPrint ? <></> : <>{buildItinNavbar(tabLabels)}</>}
                            </>
                        ) : (
                            <></>
                        )}
                        {itinerary &&
                        itineraryRelatedContent &&
                        itineraryHotelContent &&
                        itineraryDestinationContent &&
                        itineraryCountryContent &&
                        tripContent &&
                        departureContent ? (
                            <>
                                {isPrint && imagesLoaded ? (
                                    <div className={"print-itinerary-loaded"}></div>
                                ) : (
                                    <></>
                                )}
                                {/* {buildTabbedItineraryContent()} */}
                                {buildItineraryTab()}
                                {buildAccommadationTab()}
                                {buildExperienceDetailsTab()}
                                {isPrint ? (
                                    <></>
                                ) : (
                                    <PreTripInformation
                                        isBooked={isBooked}
                                        itineraryCountryContent={itineraryCountryContent}
                                        departureContent={departureContent}
                                        otpContent={otpContent}
                                        isOtpComplete={isOtpComplete}
                                    />
                                )}
                                {isPrint ? <></> : buildCountryInformationTab()}
                                {isBooked || tripType == "Bespoke" || isPrint ? (
                                    <></>
                                ) : (
                                    <TravellerTestimony tripContent={tripContent} />
                                )}
                                {isBooked || tripType == "Bespoke" || isPrint ? (
                                    <></>
                                ) : selfGuided ? (
                                    <></>
                                ) : departureContent.departures.length == 0 ? (
                                    <></>
                                ) : (
                                    buildChooseDepartureTab()
                                )}
                                {tripType == "Bespoke" || isPrint ? (
                                    <></>
                                ) : (
                                    <ContactPersonalTravelAdvisor />
                                )}
                                {isPrint ? <></> : <ScrollToTopButton mobileWidth={mobileWidth} />}
                                <BrIframeModal
                                    fullScreen={true}
                                    isOpen={isShareTripModalOpen}
                                    background={true}
                                    padding={true}
                                    previousPageHeading={"Return to trip page"}
                                    handleClick={() =>
                                        setIsShareTripModalOpen(!isShareTripModalOpen)
                                    }
                                >
                                    <div className={"small-group-share-trip"}>
                                        <MarketoForm
                                            form="shareTrip"
                                            additionalData={{
                                                formHeading: itinerary.name,
                                                tripType: "Itinerary",
                                                tripUrl: `${process.env.REACT_APP_RETURN_URL}/itinerary/Landing/${itinerary.identifier}`,
                                                tripName: itinerary.name,
                                                itinerary: itinerary,
                                                closeForm: () =>
                                                    setIsShareTripModalOpen(!isShareTripModalOpen),
                                            }}
                                        />
                                    </div>
                                </BrIframeModal>
                            </>
                        ) : (
                            <div style={{ maxWidth: "100%", margin: "auto" }}>
                                <Loading />
                            </div>
                        )}
                        {isPrint ? (
                            <></>
                        ) : (
                            <BrPhotoCredit itineraryRelatedContent={itineraryRelatedContent} />
                        )}
                    </div>
                    {loginState ? (
                        isPrint ? (
                            <></>
                        ) : (
                            <>
                                <div
                                    style={{
                                        width: 275,
                                        position: "fixed",
                                        left: 16,
                                        bottom: 75,
                                        zIndex: 99,
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        href={
                                            "https://wetu.com/Builder/Build/" +
                                            (isBooked ? "Personal" : "Sample") +
                                            "/ItineraryDetails/" +
                                            wetuId
                                        }
                                        target="_blank"
                                    >
                                        View WETU Itinerary
                                    </Button>
                                </div>

                                <div
                                    style={{
                                        width: 275,
                                        position: "fixed",
                                        left: 25,
                                        bottom: 25,
                                        zIndex: 99,
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={function () {
                                            refreshPageData();
                                        }}
                                    >
                                        Refresh Itinerary Data
                                    </Button>
                                </div>
                            </>
                        )
                    ) : (
                        ""
                    )}
                </>
            )}
        </>
    );
};

export default Itinerary;
